<script>
  import { call } from 'vuex-pathify'
  import BillingPaymentMethodForm from '@/components/account/BillingPaymentMethodForm'

  export default {
    components: {
      BillingPaymentMethodForm,
    },
    methods: {
      ...call('account', ['updateBillingInfo']),
      async submit($event) {
        if ($event.token) {
          await this.updateBillingInfo($event.token)
          this.$navigate({ name: 'AccountProfileSettings' })
          return
        }
        if ($event.type === 'credit-card') {
          this.$navigate({ name: 'AccountProfileSettingsCreditCard' })
        }
      },
    },
  }
</script>

<template>
  <section>
    <BaseHeading size="h5" tag="h2" class="mb-6">Change Payment Method</BaseHeading>

    <BillingPaymentMethodForm @submit="submit" />

    <div class="mt-6 -mb-2 flex justify-center">
      <BaseLinkStyled :to="{ name: 'AccountProfileSettings' }" color="gray" font-size="xs">
        Cancel
      </BaseLinkStyled>
    </div>
  </section>
</template>
